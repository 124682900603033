// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const images = require.context('../images', true);

global.$ = global.jQuery = global.jquery = require("jquery");
import $ from 'jquery';

require('js/jquery.slimscroll');
require('js/bootstrap.min');
require('js/detect');
require('js/vertical-menu');
require('js/toasts');
require('js/jquery-ui');
// require('src/datatables/jquery.dataTables.min');
// require('src/datatables/dataTables.bootstrap4.min');
// require('src/datatables/dataTables.responsive.min');
require('js/datetime-moment.js')
    //global.moment = require('src/moment/moment.js');
global.moment = require('src/datatables/moment.min');
require('src/datatables/moment-locale-es');
//require('src/datepicker/datepicker.min');
require('js/bootstrap-datetimepicker.js')
require('js/jquery.validate');
require('js/select2.min');
require('src/switchery/switchery')
require('js/core');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
//require('datatables.net-responsive-bs4');
// require('datatables.net-searchpanes-bs4');

var pnotify = require('src/pnotify/js/pnotify.custom.min');
window.pnotify = pnotify;

require("@nathanvda/cocoon")

import "chartkick/chart.js"
import 'stylesheets/application.scss'

import 'src/datatables/dataTables.bootstrap4.min.css'
import 'src/datatables/buttons.bootstrap4.min.css'
import 'src/datatables/responsive.bootstrap4.min.css'
import 'src/pnotify/css/pnotify.custom.min'
import 'src/select2/select2.min'
import 'src/switchery/switchery'
import 'js/jquery-ui/jquery-ui.css';
import 'js/jquery-ui';


import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

var dT_language = {
    sProcessing: "Procesando...",
    sLengthMenu: "Mostrando _MENU_ registros",
    sZeroRecords: "No se encontraron resultados",
    sEmptyTable: "Ningún dato disponible en esta tabla",
    sInfo: "Mostrando del _START_ al _END_ de _TOTAL_ registros",
    sInfoEmpty: "Mostrando del 0 al 0 de 0",
    sInfoFiltered: "(filtrado de un total de _TOTAL_ registros)",
    sInfoPostFix: "",
    sSearch: "<i class='fa fa-search'>",
    paginate: {
        previous: "Anterior",
        next: "Siguiente"
    },
    sUrl: "",
    sInfoThousands: ".",
    decimal: ',',
    thousands: '.',
    sLoadingRecords: "Cargando...",
    oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente"
    }
}

global.dT_language = dT_language;

global.tooltips_es = {
    today: 'Hoy',
    clear: 'Limpiar',
    close: 'Cerrar',
    selectMonth: 'Seleccionar Mes',
    prevMonth: 'Mes Anterior',
    nextMonth: 'Siguiente Mes',
    selectYear: 'Seleccionar Año',
    prevYear: 'Año Anterior',
    nextYear: 'Siguiente Año',
    selectDecade: 'Seleccionar Decada',
    prevDecade: 'Decada Anterior',
    nextDecade: 'Siguiente Decada',
    prevCentury: 'Centenario Anterior',
    nextCentury: 'Siguiente Centenario',
    pickHour: 'Seleccionar Hora',
    incrementHour: 'Incrementar Hora',
    decrementHour: 'Decrementar Hora',
    pickMinute: 'Seleccionar Minuto',
    incrementMinute: 'Incrementar Minuto',
    decrementMinute: 'Decrementar Minuto',
    pickSecond: 'Seleccionar Segundo',
    incrementSecond: 'Incrementar Segundo',
    decrementSecond: 'Decrementar Segundo',
    togglePeriod: 'Activar Periodo',
    selectTime: 'Seleccionar Tiempo'
}

const destroy_dataTableObject = function() {

    //
    var table = $('.dataTable_server_side')
        //table = window.document.querySelector('[id^=DataTables_Table]')
    try {
        //table.DataTable().state.clear()
        table.DataTable().destroy()
            //
    } catch {
        debugger

    }
}

global.destroy_dataTableObject = destroy_dataTableObject;

//window.addEventListener("turbolinks:before-cache", () => {
//    destroy_dataTableObject
//})
//
//document.addEventListener('DOMContentLoaded', () => {
//    destroy_dataTableObject
//})

//window.document.addEventListener("DOMContentLoaded", destroy_dataTableObject, false);

//window.addEventListener("body:before-cache", () => {
//    destroy_dataTableObject
//})

window.hideSpinner = function() {
  document.getElementById('spinner').style.display = 'none';
}

window.showSpinner = function() {
  document.getElementById('spinner').style.display = 'block';
}

window.addEventListener("turbolinks:request-start", () => {
  showSpinner();
})

window.addEventListener("turbolinks:request-end", () => {
  hideSpinner();
})

window.addEventListener("turbolinks:load", () => {
    //destroy_dataTableObject

    // comaWritter()
    // comaReader()

    $('#modal').on('show.bs.modal', function(e) {
        $('#modal').find('#error_explanation').prop('hidden', true)
        $('#modal').find('#error_explanation').html("");
        $('#modal').find('#error_explanation').removeClass("success alert-success")
        $('#modal').find('#error_explanation').addClass("alert alert-danger")
        $('#modal').find('.extra-buttons').html('')
        $(".modal-xl").css('max-width', '1140px')

        comaWritter()
        comaReader()
    })

    $('.numberseparator').each(function() {
        normalized_price = $(this).val().replaceAll('.', '').replaceAll(',', '.');
        $(this).val(normalized_price);
    });

    $('.dataTable').DataTable({
        responsive: true,
        aaSorting: [],
        language: dT_language,
        paging: true,
        searching: true,
        columnDefs: [{
            targets: 'no-sort',
            orderable: false,
        }]
    });

    $(".select2").select2({
        tags: "true",
        allowClear: true,
        theme: 'classic'
    });

    $(".select2_regular").select2({ width: 'resolve' });

    $(".select2_tokenizable").select2({
        width: 'resolve',
        tags: "true",
        allowClear: true,
        theme: 'classic',
        //placeholder: 'Valor1, Valor2, ...',
        tokenSeparators: [',']
    });

    $('.select2_no_search').select2({
        width: 'resolve',
        minimumResultsForSearch: Infinity
    });

    $.fn.dataTable.moment('DD/MM/YYYY', 'es');
    $.fn.dataTable.moment('DD/MM/YYYY HH:mm:ss', 'es');

    $('.date_field').datetimepicker({
        locale: "es",
        tooltips: tooltips_es,
        toolbarPlacement: 'top',
        showTodayButton: true,
        showClear: true,
        showClose: true,
        widgetPositioning: {
            horizontal: 'right',
            vertical: 'bottom'
        },
        inline: false,
        format: "L"
    });

    $(document).on('shown.bs.collapse', '.collapse', function(e) {
        var id = $(this).attr('id')
        if (id != undefined) {
            var sign = $('#sign_' + id)
            sign.removeClass('fa-chevron-down')
            sign.addClass('fa-chevron-up')
        }
    })

    $(document).on('hidden.bs.collapse', '.collapse', function(e) {
        var id = $(this).attr('id')
        if (id != undefined) {
            var sign = $('#sign_' + id)
            sign.removeClass('fa-chevron-up')
            sign.addClass('fa-chevron-down')
        }
    })
});

window.form_validate = function() {
    $('form').validate({
        errorPlacement: function(error, element) {
            error.addClass('word-tuncate invalid-feedback-static pull-right')
            element.find('.form-control').first().append(error)
        },
        errorElement: 'row',
        focusInvalid: true,
        invalidHandler: function(form, validator) {
            if (!validator.numberOfInvalids())
                return;

            $('html, body').animate({
                scrollTop: $(validator.errorList[0].element).offset().top - 500
            });
        }
    });
}

const show_toast = ({ message, type, title } = { message: '--', type: 'info', title: false }) => {
    //type: ["notice", "info", "success", "error"][

    new pnotify({
        title: title,
        text: message,
        type: type,
        addclass: 'custom-notification-alert-' + type + ' notification',
        icon: true,
        buttons: {
            sticker: true
        }
    });
};

global.show_toast = show_toast;

const index_server_side = ({ class_name, path }) => {
    //Datatable serverside
    destroy_dataTableObject.call()
    let table = $(class_name)
    table.DataTable({
        responsive: true,
        aaSorting: [],
        language: dT_language,
        paging: true,
        searching: false,
        processing: true,
        serverSide: true,
        info: true,
        bDestroy: true,
        columnDefs: [{
            targets: 'no-sort',
            orderable: false,
        }],
        ajax: {
            type: "POST",
            url: path,
            timeout: 6000 // sets timeout to 2,5 minutes
        },
        error: function(xhr, jqAjaxerror, thrown) {
            show_toast({ type: 'danger', message: 'Ha ocurrido un error' })
        },
        // dom: 'Bfrtip',
        // buttons: [
        //     'copy', 'csv', 'excel', 'pdf', 'print'
        // ]
    });
    // search: {
    //     return: true
    // },
    // bScrollCollapse: true,
    // columnDefs: [
    //     { responsivePriority: 1, targets: -1 },
    //     {
    //         targets: 'no-sort',
    //         orderable: false,
    //     },
    //     {
    //         width: '1.5em',
    //         className: 'dt-head-right',
    //         targets: [0, -1],
    //     }
    // ],
    // fixedColumns: {
    //     left: 1,
    //     right: 1
    // },
    // bStateSave: false,

    // table.DataTable().clear().draw();

    //Estilo de buscador
    var container = $(".dataTables_length").parent().parent()
    if (container !== undefined) {
        container.attr('class', "form-inline")
        var alignment = container.find(".col-sm-12")
        alignment.first().attr('class', "d-flex justify-content-end w-100 ml-2 mt-2")
            //alignment.last().attr('display', "d-flex justify-content-end w-50 mr-2 mt-2")
    }
    // End Datatable serverside
}

global.index_server_side = index_server_side;

const generic_server_side = ({ class_name, path }) => {
    //Datatable serverside

    destroy_dataTableObject.call()
    let table = $(class_name)
    table.DataTable({
        responsive: true,
        aaSorting: [],
        language: dT_language,
        paging: true,
        searching: true,
        bDestroy: true,
        info: true,
        processing: true,
        serverSide: true,
        bStateSave: false,
        scrollX: true,
        columnDefs: [{
            targets: 'no-sort',
            orderable: false,
        }],
        search: {
            return: true
        },
        ajax: {
            type: "POST",
            url: path,
        },
        error: function(xhr, jqAjaxerror, thrown) {
            show_toast({ type: 'danger', message: 'Ha ocurrido un error' })
        }
    });
    //Estilo de buscador
    var container = $(".dataTables_length").parent().parent()
    if (container !== undefined) {
        container.attr('class', "d-flex justify-content-around mt-2 mb-1 w-100")
        var alignment = container.find(".col-sm-12")
        alignment.first().attr('class', "d-flex justify-content-start w-50 ml-2")
        alignment.last().attr('class', "d-flex justify-content-end w-50 mr-2")
    }
    // End Datatable serverside
}

global.generic_server_side = generic_server_side;

const comaWritter = function() {
    $(".numberseparator").on({
        "focus": function(event) {
            $(event.target).select();
        },
        "keyup": function(event) {
            $(event.target).val(function(index, value) {
                return value.replace(/\D/g, "")
                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
            });
        }
    });
}

global.comaWritter = comaWritter;

const comaReader = function() {
    $('.numberseparator').each(function() {
        if (parseInt($(this).val().replace(/\D/g, "")) !== 0) {
            $(this).val($(this).val().replace('.', ',').replaceAll(/\B(?=(\d{3})+(?!\d)\.?)/g, "."));
        }
    });
}

global.comaReader = comaReader;


const add_sticky = function(id) {
  var identifier = document.getElementById(id);
  if (identifier !== null) {
    var sticky = identifier.offsetTop;
    if (window.pageYOffset >= sticky) {
      identifier.classList.add("topbar")
      identifier.classList.add("ficha-top-styles")
    } else {
      identifier.classList.remove("topbar");
      identifier.classList.remove("ficha-top-styles")
    }
  }
}

global.add_sticky = add_sticky;

const remote_select2 = function(id, path, placeholder='', extra_params={}, multiple = false, limit= false){
    $(`#${id}`).select2({
      width: '100%',
      language: {
        "noResults": function(){
            return "No se encontraron resultados";
        }
    },
      placeholder: placeholder,
      multiple: multiple,
      maximumSelectionLength: limit,
      formatSelectionTooBig: function (limit) {
        return `Solo se puede elegir un maximo de ${limit} opciones.`;
      },
      ajax: {
      url: path,
      dataType: 'json',
      data: function (params) {
        if(params["term"] != undefined){
          extra_params["term"] = params["term"]
        }
        return extra_params;
      }
    }})
  }

global.initialize_remote_select2 = remote_select2
